// App.js
import React, { useEffect, useState } from "react";
import { db } from "./firebase"; // Importera din firebase-konfiguration
import { collection, getDocs } from "firebase/firestore"; // Importera nödvändiga funktioner från Firestore

function App() {
  const [data, setData] = useState([]); // State för att lagra data

  // Funktion för att hämta data från Firestore
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "dinSamling")); // Byt ut "dinSamling" mot namnet på din samling
      const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(items); // Sätt data i state
    } catch (error) {
      console.error("Fel vid hämtning av data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Hämta data när komponenten laddas
  }, []);

  return (
    <div>
      <h1>Välkommen till Husapp!</h1>
      <h2>Data från Firestore:</h2>
      <ul>
        {data.map((item) => (
          <li key={item.id}>{JSON.stringify(item)}</li> // Visa varje objekt i listan
        ))}
      </ul>
    </div>
  );
}

export default App;